import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import styled from "styled-components";
import hand from "../../image/hand.png";
import "../../../CSS/Login.scss";
import Axios from "axios";
import TopMenu from "./TopMenu";
import { useAuth } from "../../../Utility/Auth";
import { useLocation } from "react-router-dom";

  const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const refPassword = useRef(null);
  const refName = useRef(null);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user, login } = useAuth();
 
 
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const username = refName.current.value.trim().toLowerCase();
    const password = refPassword.current.value.trim();

    if (!username || !password) {
      setError("Please enter both username and password.");
      return;
    }

    try {
      const response = await Axios.get(
      /*   "http://localhost:3000/php/main/display.php" */
      "https://igbocommunitynwi.com/php/main/display.php" 
      );
      const namesArray = response.data.split("\r\n");
      const cleanedNames = namesArray.filter((name) => name.trim() !== "");

      const found = cleanedNames.some((name) => {
        return name.trim().toLowerCase() === username || name.trim() === password;
      });

      if (found) {
        try {
          await Axios.post(
           /*  "http://localhost:3000/php/main/logn.php", */
            "https://igbocommunitynwi.com/php/main/logn.php",
            { name: username, password: password }
          );
           login({ name: username });  // Set the authentication state
          setError("");
          refName.current.value = ""; // Clear input fields
          refPassword.current.value = "";
          navigate('/Authentication'); // Redirect to the desired private page

        } catch (error) {
          console.error("Error logging in:", error);
          setError("An error occurred. Please try again later.");
        }
      } else {
        setError("Username or password is incorrect.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred. Please try again later.");
    }
  };
  return (
    <section>
      <>
        {<TopMenu className="fixed top-0" />}

        <LOGIN className="logn min-w-screen min-h-screen">
          <div className="container form-containers">
            <h2 className="relative top-1">sign in Here</h2>
            <div className="brand relative top-3 ">
              <img src={hand} alt="brand-image" width="120" height="120"/>
            </div>
            <div className="title-headers relative top-2">
              <h4 className="">We are a Charitable Group</h4>
            </div>
            <form action="" className="forms " onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Member</label>
                <input
                  style={{ zIndex: "1200" }}
                  type="text"
                  placeholder="Enter you Name or Email"
                  className="form-control"
                  ref={refName}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
                <p className="text-light">{error}</p>
              </div>

              <div>
                <label htmlFor="text">Password</label>
                <input
                  type="password"
                  placeholder="Enter your Password"
                  className="form-control"
                  ref={refPassword}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className=" btn btn-lg btn-info form-control"
                >
                  LOGIN
                </button>
              </div>
              <div className="account-condition mt-2">
                <h6 className="text-light text-muted">
                  have no account ?{" "}
                  <Link to="/SignUp" className="text-decoration-none me-5 ml-3">
                    SIGN UP
                  </Link>
                </h6>
              </div>
            </form>
          </div>
        </LOGIN>
      </>
    </section>
  );
};

export default Login;

const LOGIN = styled.div`
  padding: 0;
  margin-top: 0;
  box-sizing: border-box;
  .account-condition a {
    background-color: red !important;
    background-image: linear-gradient(45deg, #f3ec78, #af4261) !important;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent !important;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent !important;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
  label {
    font-size: 0.8rem;
    font-weight: 600;
    color: ivory !important;
    z-index: 2500;
  }
  button {
    font-size: 1.4rem;
    font-weight: 700;
    z-index: 1000;
    background: green !important;
    border: none;
    text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.6);
    :focus {
      box-shadow: none;
    }
  }
  .nav-brand {
    z-index: 2500 !important;
  }
  .title-headers {
    z-index: 2500;
    h4 {
      color: #00fb1d;
      background: transparent;
      text-shadow: 3px 5px 2px #474747;
      z-index: 2500;
    }
  }
  .form-containers {
    padding-top: 5rem;
    z-index: 2500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: black;
      opacity: 0.6;
      z-index: -2;
    }
  }

  .forms {
    z-index: 3000;
    min-height: 100%;
    height: auto;
    min-width: 60%;

    box-shadow: rgba(54, 3, 135, 0.187) 0px 22px 70px 4px;

    input {
      margin-bottom: 0.8rem;
      padding: 0.5rem;
      border: none;
      :focus {
        box-shadow: none;
        border: none;
      }
    }
  }
`;
