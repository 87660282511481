import { Navigate } from 'react-router-dom';
import { useAuth } from './Auth';
import { useLocation } from 'react-router-dom';

const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation()
  if (!auth.user) {
    // If user is not authenticated, redirect to the login page
    return <Navigate to="/Login" state={{path: location.pathname}}/>;
  } else {
    return children;
  }
};

export default RequireAuth;



























/* import React from 'react'
import { Navigate, useLocation} from 'react-router-dom';
import { useAuth } from '../Auth'
import SignIn from './Header/DropdownContainer/SignUp';

const ReguireAuth = ({children}) => {
    const auth = useAuth();
    const location = useLocation();
    if(!auth.user){
   return <Navigate to='/Login' state={{path: location.pathname}}/>
    }
  return children 
}

export default ReguireAuth */