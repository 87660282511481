import React,{useContext} from 'react'
import AuthComponent from './AuthComponent'
import { config } from '@fortawesome/fontawesome-svg-core'
import {  useConfirmed } from './AuthComponent'
 
const Practice = () => {
  const {authorized} =  useConfirmed()
  return (
    <div className='min-w-screen min-h-screen bg-slate-700 py-5'>
   <h1 className='text-light  text-center'>Allowed User</h1>
   <div className="box d-flex justify-center align-middle p-5">
   {authorized()}
   </div>
    </div>
  )
}

export default Practice