import React,{useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import axios from 'axios'

const CodeOfConduct = () => {
    const [error, setError] = useState("");
    const [errorname, setErrorName] = useState("");
    const [errorpassword, setErrorPw] = useState("");
    const [success, setSuccess] = useState("");
    const [modals, setModals] = useState(false);
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const myRef = useRef(null);
    const nameRef = useRef(null);
    const pwRef = useRef(null);
    const [isChecked, setIsChecked] = useState(true);
  

  
    const handleSubmit = async () => {
      const refPassword = pwRef.current.value;
      const refNameEmail = nameRef.current.value;
      const hasSpecialCharacters = /[^\w\s;:&%$£"()#~?<>]/.test(refNameEmail); // Checks if refName contains any special characters
      const hasUppercase = /[A-Z]/.test(refPassword);
  
      if(refPassword === "" || refNameEmail === "") {
        setErrorName("Please input Fields must not be left Empty Thanks");
      } else if (refPassword.length < 6) {
        setErrorPw("Password must contain at least 6 characters long");
      } else if (!hasUppercase) {
        setErrorPw("Password must contain at least one uppercase letter");
      } else {
        try {
          // Check if the username/email is already taken before opening the modal
          const response = await axios.get(`http://localhost:4500/texts`);
          const myData = response.data;
  
          // Assuming you have the user name to check
          let namesEmailsArray = []; // Initialize an array to store all names and emails
  
          // Populate namesEmailsArray with all names and emails from myData
          for (let i = 0; i < myData.length; i++) {
            let dataValueName = myData[i].name;
            namesEmailsArray.push(dataValueName);
          }
  
          // Check if refNameEmail exists in namesEmailsArray
          if (namesEmailsArray.includes(refNameEmail)) {
            console.log(namesEmailsArray,"", refNameEmail)
            setErrorName("Sorry User Name/Email has been Taken");
          } else {
            setModals(true);
          }
        } catch (error) {
          console.log("Error:", error.message);
          setError("An error occurred. Please try again later.");
        }
      }
    };
  
      const handleModel = async () => {
      const checkbox = myRef.current.checked;
      const nameValue = nameRef.current.value;
      const refPassword = pwRef.current.value;
  
      if (!checkbox) {
        setError("Please, You're required to check the box to agree with our Code of Conducts Thanks");
      } else {
        try {
          const response = await axios.post("http://localhost:4500/join", {
            name: name,
            password: password
          });
  
          console.log("Response:", response.data);
  
          // If the request is successful, show success message and reset form
          setSuccess("Congratulations " + nameValue + " you're welcome to the Group \n" +  <button>Proceed to Payment,  {""} Reg Fee is €15</button> );
       
          document.getElementById("myForm").reset();
          setModals(false);
          setErrorName("");
          setErrorPw("");
        } catch (error) {
          // If there's an error, handle it
      
            console.log("Error:", error.message);
            setError("An error occurred. Please try again later.");
          
        }
      }
    };
  

  const update = ()=>{
  setIsChecked(!isChecked)
}
    

/* alert( "Congratulations  "+ refName + "  You're Welcome to the Group 🤝 "  )   
closeModel();    */ 


  return (
    <section className='min-w-screen min-h-screen p-3 bg-slate-500/100 backdrop-brightness-95' style={{zIndex:'55000'}}>
        <h6 className='w-75 text-center bg-purple-600 text-light m-auto'>{success}</h6>
<div className="form w-75 m-auto z-auto" style={{zIndex:'2000'}} >
<form action="" className='d-flex p-3 flex-col justify-center align-middle myForm' id="myForm">
    <div className="imput">
        <label htmlFor="name:">NAME | EMAIL</label>
    
        <input type="text" ref={nameRef} className='form-control' onChange={(e)=>setName(e.target.value)} />
        {errorname}
    </div>
    <div className="imput">
        <label htmlFor="name:">PASSWORD</label>
        <input type="password" ref={pwRef} className='form-control' onChange={(e)=>setPassword(e.target.value)}/>
        {errorpassword}
    </div>
    <button type='button' className="btn btn-lg btn-success mt-4" onClick={handleSubmit} >Register</button>
</form>
</div>
{ modals &&
    <ModalContainer div className="modal">

       
    <div className="modal-content container relative top-46">
        <h2 className='py-3'>ICANWI Code of Conduct</h2>
        <h4 className=' text-primary'>Please read and acknowledge the terms and conditions</h4>
        
        <p>Our Code of Conduct serves as a foundational framework of principles and guidelines meticulously crafted to govern the behavior of all members within the ICANWI community. It reflects our collective commitment to fostering an environment of respect, integrity, and inclusivity, ensuring that every interaction, whether in meetings or events, upholds the values we hold dear.
    </p>
     <h5 className='relative top-1 text-primary'><strong>Key Principles:</strong></h5>
     <p>
    <span className='font-bold me-1'><strong>Respect:</strong></span>Treat every member with dignity and respect, regardless of differences in opinion, background, or status. Valuing diverse perspectives enriches our community.<br/>
    <span className='font-bold me-1'>Integrity:</span> Conduct yourself with honesty and transparency in all interactions. Uphold the highest ethical standards, both within the group and in external engagements.
    <br/>

    <span className='font-bold me-1'>Collaboration:</span> Foster a spirit of collaboration and cooperation, recognizing that collective effort and teamwork are essential for achieving our shared goals.
    <br/>
    <span className='font-bold me-1'>Accountability:</span> Take responsibility for your actions and their impact on the community. Admit mistakes, learn from them, and strive to do better.
    <br/>
    <h5 className='relative top-2 bottom-2 text-primary'><strong>Rules and Guidelines:</strong></h5>
    <span className='font-bold me-1'>Professionalism:</span> Foster a spirit of collaboration and cooperation, recognizing that collective effort and teamwork are essential for achieving our shared goals.
    <br/>
    <span className='font-bold me-1'>Confidentiality:</span> Respect the privacy and confidentiality of fellow members. Refrain from disclosing sensitive information shared within the group without explicit consent.
    <br/>
    <span className='font-bold me-1'>Conflict Resolution:</span> Resolve conflicts and disagreements amicably and respectfully, seeking mediation or assistance from group leaders when necessary..
    <br/>
    <span className='font-bold me-1'>Compliance:</span>: Adhere to all relevant laws, regulations, and policies governing our activities, both within the group and in external engagements
    
    <h5 className='relative top-2 bottom-2 text-primary'><strong>Enforcement and Consequences:</strong></h5>
    <p> Violation of the Code of Conduct may result in disciplinary action, determined on a case-by-case basis by group leaders or designated moderators. Consequences may include warnings, temporary suspension of privileges, or permanent expulsion from the group, depending on the severity of the offense.</p>
    </p>
        <label className='text-black'>
            {error && <p className='text-info'>{error}</p> } 
           
            <input type="checkbox" checked={isChecked} ref={myRef}  onChange={update}/>
            <span className='text-black ml-3'>I have read and agree to the terms and conditions.</span>
        </label>
        <button className='btn btn-info btn-lg text-light 'onClick={handleModel}>Submit</button>
        <button className="cancel-button btn btn-lg btn-info mt-2 text-light" onClick={()=>setModals(false)}

        >Cancel</button>
        <div className='text-red-800 bg-black text-center text-lg'></div>
 
 </div>

 </ModalContainer>
}
 </section>

  )
}

export default CodeOfConduct

const ModalContainer = styled.div`

    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 45000;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.5);
    animation: slideDown 0.5s ease; /* Smooth sliding animation */

    @keyframes slideDown {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0);
        }
    }


.modal-content {
    background-color: #fcfcfc;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px 4rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.3s ease forwards;
    h5{
  font-family: Arial, Helvetica, sans-serif;
 color:black;

}
labe{
    display: block;
    margin-bottom: 10px;
}
input[type="checkbox"]{
    padding:5%;
    border:1px solid black !important;
}
p{
font-family:  sans-serif;
 font-weight: 300;
font-style: normal;
}
h2 {
    margin-top: 0;
    color:#000 !important;
    font-family: Arial, Helvetica, sans-serif;
}

}


`