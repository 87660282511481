
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import image1 from '../image/AfeventWeb1.webp'
import image2 from '../image/AfeventWeb2.webp'
import image3 from '../image/AFeventWeb3.webp'

const images = [
  image1,
  image2,
  image3
];

function MyHero() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    },5000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Heros>
        <h1 className="display-4 text-center text-light min-w-screen p-3">My Hero Page</h1>
        <div className="hero-container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`hero-image ${index === currentImageIndex ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      ))}
    </div>
    </Heros>
  );
}

export default MyHero;

const Heros = styled.div`

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;

  .hero-container {
  width: 80%;
  max-width: 1200px;
  height: 600px;
  perspective: 1000px;
  overflow: hidden;
}
.hero-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 10s ease-in-out, opacity 10s ease-in-out;
  opacity: 0;
  transform: scale(0.8) rotateY(0deg); /* Start with a rotated and scaled state */
}

.hero-image.active {
  opacity: 1;
  transform: scale(1) rotateY(720deg); /* Rapidly rotate 720 degrees */
  z-index: 1;
}

.hero-image:not(.active) {
  z-index: 0;
}

`