import React from 'react';
import styled from 'styled-components';
import logos from '../image/logo2.png' 
import { NavLink } from 'react-bootstrap';

function Footer2() {
  return (
    <section className='min-w-screen bg-white' style={{width:'100vw ' , background:'white'}}>
    <FOOT className='footer2'>
        <div className="container">
        <div className="row relative">
          <div className="col-lg-8 col-md-6 col-sm-12 add relative">
            <h4>Other Networks</h4>
        {/*   <NavLink  class="logos text-decoration-none " to ="/">
            <img src={logos} alt={'newLogo1'} />
             </NavLink> */}
            <p>
              <strong>Address:</strong> St. Mary’s Parish Centre, Main Street Stranorlar, Co.Donegal
              <br />
              <strong>Eircode:</strong> F93 EK52 Ireland
              <br/>
              <strong>Phone:</strong> +44 (759) 6573084, +353 (894) 060723
              <br />
              <strong>Meeting  Date | Time:</strong>  Second sunday of each calendar month at 4pm
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <h4>Location</h4>
            <iframe width="520" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=St.%20Mary%E2%80%99s%20Parish%20Centre,%20Main%20Street%20Stranorlar%20Stranorlar+(Meeting%20Venue)&amp;t=h&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <p>
          &copy; {new Date().getFullYear()} Copyright ICANWI | Developed by{' '}
          <a href=" https://aludevs.com/" target="_blank" rel="noopener noreferrer">
            Aludevs.com
          </a>
        </p>
      </div>
    </FOOT>
    </section>
  );
}

export default Footer2;

const FOOT = styled.footer`
padding: 60px 0;
color:#ECAD7F;
/*  img{
  position:absolute;
  left:-2.8rem;
  bottom:-2.5rem;
  max-width:200px;
  max-height:200px;
  min-width:100px;
  position:relative;

} */
/* .add{
 transform:translateY(-5rem)!important
} */
h4 {

  margin-bottom: 20px;
  font-weight: bold;
  color:#E10600FF ;
}

 p {
  font-size: 16px;
  color:#010005 !important;
  font-weight:400;
  line-height:5vh;
  a{
    color:#E10600FF ;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    font-weight:500;

  }
}

    
  

@media (max-width: 768px) {

    padding: 40px 0;
  

   h4 {
    margin-top: 30px;
    margin-bottom: 24px;

  }

   p {
    font-size: 16px;
  }
}

`
