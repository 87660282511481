import React,{useState, useContext, createContext, useRef, Children} from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BrightnessLow, Justify } from 'react-bootstrap-icons'

 const ConfidentialFile = createContext(null)

export const AuthComponent = ({children}) => {
const{pathname} = useLocation();
const [authes, setAuthes] = useState(false) 
const [btns, setbtnsToggle] = useState(true) 

 const authorized = () => {
  return (
    <div className='my-3'>
      <h3 className=''>Hello Dude, You gat the power to Enjoy Explore our information 🏋️‍♀️💃 
       </h3>
    </div>
  )
}
const notAuthorized = () => {
  return (
    <div className='my-3'>
   <h3 className='auth-inner'>
 Heeey...! you have no legal right to Access our confidential Data 🏃‍♀️🏃‍♀️</h3>
    </div>
  )
}
  const values = {
    authes,
    btns,
    notAuthorized,
    authorized
  }
return (
<ConfidentialFile.Provider value = {values}>
  {children}
  </ConfidentialFile.Provider>
  )
}

export const  useConfirmed = () =>{
return useContext(ConfidentialFile)
}
/* export const ConfirmedAuth = ()=>{
return useContext(ConfidentialFile)
}  */


const AuthComponents = styled.div`
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
    color: white !important;
    flex-direction: column;
    .auth-inner{
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;

}

`