import React from "react";
import styled from "styled-components";

const Objectives = () => {
  return (
    <DIVBOXS className="min-w-screen my-16 top-8">
      <div className="page">
        <h1 className="text-center">What We Do</h1>
        <p>
          Since 2010, the Igbo Cultural Association Northwest Ireland has been
          committed to promoting cultural integration, community programs, peace
          and unity. Our focus is to bring people from diverse cultural
          backgrounds together, fostering understanding, mutual respect and
          support. This involves recognizing and appreciating the differences
          and similarities between cultures, and working towards creating a
          cohesive society. As the pictures below illustrate, we have been
          dedicated to achieving our objectives, tirelessly striving and
          incorporating innovative frameworks while adapting to change. In
          conclusion, this is what we do.
        </p>
      </div>
    </DIVBOXS>
  );
};

export default Objectives;
  const DIVBOXS = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  position: relative;
  min-height:400px;
  height: auto;
  

  @media(max-width:786px){
    transform: translateY(-5rem) !important;

  }
  .page{
  display:flex;
  justify-content:center;
  align-items:center;
  position: relative;
    max-width: 85% ! important;
    background:transparent, linear-gradient(336deg, rgba(6, 6, 41, 0.8), rgba(0, 0, 255, 0) 70.71%) !important;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
     border: 3px solid rgba(81, 0, 128, 0.294);
     margin:auto;
     height:100%;
     @media (max-width: 992px) {
      border:none !important;
     }
    @media (max-width: 786px) {
      min-width: 100vw !important;
      padding-top:1rem;
    }
   }

  h1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight:800;
    font-size: 2.8rem;
    font-family: Arial, Helvetica, sans-serif;
    padding: 3rem 2rem;
    text-align: center;
    background-color: red !important;
    background-image: linear-gradient(45deg, #f3ec78, #af4261) !important;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent !important;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent !important;
    font-family: Arial, sans-serif;
    @media (max-width: 786px) {
      padding: 1rem 0 !important;
        font-size: 2.5rem !important;
    font-weight:800 !important;

    }
  }
  p{
    color: #ffffff !important;
    padding: 0rem 5rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height:5vh;
       @media (max-width: 786px) {
        padding: 0 1rem !important;
        min-width:90vw !important;
        line-height:5.5vh !important

      }
    }
`
