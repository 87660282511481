
import   {react, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, Button} from 'react-bootstrap';
import styled from "styled-components";
import Axios from "axios";
import Link from 'react-dom';
import { useNavigate } from "react-router-dom";




  const Register = () => {

const Navigate = useNavigate();
    return (
<>
      <REG className="mode">
        
    <button className="reg2-btn "><span className="Reg" onClick={()=>{
      Navigate('/SignUp')
    }}>Register</span></button>
     
      </REG>
      </>
  )
}

export default Register
const REG = styled.div`
 .reg2-btn {
    border:none !important;
    z-index:1000;
    transition: all 0.3s ease;
   
    &:focus{
        outline:none !important;
        border:none !important;
        box-shadow:none !important;
    }
    .Reg{
        background-color: red !important;
        background-image: linear-gradient(45deg, #f3ec78, #af4261) !important;
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent !important; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent !important;
        font-size: 1.2rem;
        font-weight: bold;
        font-family: Arial, sans-serif;
    }
 }
 `
