import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, Button, NavLink} from 'react-bootstrap';
import styled from "styled-components";
import Link from 'react-dom'
import Modals from './DropdownContainer/SignUp';

const ResetPassword = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  return (
    <div className='container'>
<>
<REG className="mode">
        <NavLink className=" mx-n1   relative 
         text-decoration-none " 
         onClick={handleShow}>
         <span className="Reg">Forgot your password? </span> 
        </NavLink>
        <Modal show={show} onHide={handleClose} centered 
         className="card-body p-4 modes p-sm-5 P-5 card-registration">
          <Modal.Header className="modehead" closeButton>
            <Modal.Title className='modet'>
            
            <h5 className="card-title text-light text-center mb-5 fw-light fs-5">                          
</h5>
            </Modal.Title>
          </Modal.Header>

     <Modal.Body className="bg-dark">      
     <form className="mt-n2">
     <h6 className='text-light text-bold pb-2'>Please Enter Your Email to Reset your Password.</h6>

        <div className="form-floating mb-3">

            <input type="email" className="form-control" id="floatingInputEmail"
                placeholder="name@example.com" />
            <label for="floatingInputEmail">Email address</label>
        </div>
        <hr />
      
        <div className="d-grid mb-2">
            <button className="btn btn-lg btn-secondary btn-login fw-bold text-uppercase"
                type="submit">Reset Password</button>
        </div>
    
         <div className='authenticate d-flex justify-contet-between mt-3' >
         <a href ='/SignIn' className="d-block text-center mt-2 small" >
            Have an account? Sign In</a>
        <span href ='/SignIn' className="d-block text-center mt-2 small" >
            Have No account? Sign Up<span className='sign-ups'></span>
            <Modals/>
            </span>
         </div>
       
        <hr className="my-4" />
   
    </form>  

    </Modal.Body>
          <Modal.Footer>
          <div className="modal-footer">
          <Button variant="danger btn-lg" onClick={handleClose}>
              Close
            </Button>
           </div>

          </Modal.Footer>
        </Modal>
      </REG>
      </>

    </div>
  )
}

export default ResetPassword

const REG = styled.div`
 .reg2-btn {
    z-index:1000;
    box-shadow: rgba(211, 51, 255, 0.166) 0px 22px 70px 4px;
   
 }
 .Reg{
    @media(max-width:992px){
      padding-left:1rem !important;
        &:hover{
            background: transparent !important;
        }
    }
 }
 `
