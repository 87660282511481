
import React, { useEffect } from 'react';
import styled from 'styled-components';

const PayPalButton = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.paypal.com/sdk/js?client-id=BAAFyr6rd_36SdxiB29sR04bzKhI9ZJIZOg_GrYi22jJ_qVY-G7tboUf8YtRqlreXxMfUfWHWFUID-lvGw&components=hosted-buttons&disable-funding=venmo&currency=EUR';
    script.async = true;

    script.onload = () => {
      // Initialize the PayPal button only after the script has loaded
      window.paypal.HostedButtons({
        hostedButtonId: "KWGSGY99VYKM8",
      }).render("#paypal-container-KWGSGY99VYKM8");
    };

    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  return (
    <PayPals id="paypal-container-KWGSGY99VYKM8"></PayPals>
  );
};

export default PayPalButton;

const PayPals = styled.div`
  color:black;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;

  cursor: pointer;
  padding: 0 3rem;
::placeholder{
    background: red !important;
}




`