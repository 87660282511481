import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import Gmenu from './DropdownContainer/Gmenu.jsx'
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from '../../Utility/Auth.js';
import { useLocation } from 'react-router';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Prev from '../MinutesPage/PrivatePage.js';
import PrivatePage from '../MinutesPage/PrivatePage.js';
import { Outlet } from 'react-router-dom';




const Authentication = () => {

const navigate =useNavigate();
const location = useLocation();
const redirectPath = location.state?.path || '/PrivatePage'
 
const authRef = useRef(null);
const errorRef = useRef(null);
const [error, setError] = useState("")
const [count, setCount] = useState(3)
const [user, setUser] = useState()
const PIN = process.env.REACT_APP_PIN;

useEffect(()=>{
console.log(`authentication ${PIN}`)
}, [])

let attempt = count;
const handleCount = () => {
  setCount((prev) => prev - 1);
  if (count === 1) {
    navigate('/');
  }
};

const handleSubmit = (event) => {
  event.preventDefault();
  if (authRef.current.value !== PIN
  ) {
    errorRef.current.innerText = `The pin you entered is incorrect and you have only ${count - 1} attempt left`;
    handleCount();
  } else {
    navigate(redirectPath, {replace:true}); // Redirect to the desired private page

  }

};
 
  return (
    <>
    {<Gmenu/>}
    <Sections className= ' bg-sky-500 min-h-screen min-w-screen'>
     <div className="container m-auto relative top-36 d-flex flex-col p-5 bg-sky-800 min-h-full">
    <h1 className="w-100 p-3 text-light">Membership Authentication</h1>
     <form action="" className="w-75 p-2" onSubmit={handleSubmit}>
        <h3>Please Enter Your Pin</h3>
       <p className='error text-warning' ref={errorRef}></p>
        <div className="pin-inp">
        <input type="text" className='form-control' required placeholder='Please Enter Your Pin' ref={authRef}/>
        </div>
        <div className="button">
        <button className= "btn btn-inline-info border  mt-3 " type='submit'>
          <span className='text-light font-bold text-xl 0 '>Authenticate</span>
          </button>
        </div>

     </form>
     </div>
    </Sections>
    </>
  )
}

export default Authentication

const Sections = styled.div`
margin:0;
padding:0;

`