import React, { useState } from 'react';
/* import './PaymentGateway.css'; // Import CSS for styling
 */import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Gmenu from '../DropdownContainer/Gmenu'

const Payment = () => {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && amount && paymentType) {
      setSubmitted(true);
    }
  };

  // Handler for Cancel button
  const handleCancel = () => {
    setSubmitted(false);
    setName('');
    setAmount('');
    setPaymentType('');
  };

  // Handler for OK button
  const handleOk = () => {
  navigate('/Donate')
  };

  return (
    <>
       {<Gmenu />}
      

    <PaymentSystem className="payment-gateway-container">
    <div className="  p-8 d-flex flex-col justify-center align-middle gap-10 container relative top-20" style={{minHeight:'150px', color:'black'}}>
          <p className="text-center text-secondary h1-title">
            {" "}
            We are driven by a commitment to solidarity, dedicated to serving the community and providing humanitarian aid. Our strength lies in delivering meaningful results, made possible by your support 🙏{" "}
          </p>
        </div>
      {!submitted ? (
        <form className="payment-form relative top-16" onSubmit={handleSubmit}>
          <h2>Payment Procedure</h2>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input 
              className='text-dark'
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="amount">Amount:</label>
            <input  
              className='text-dark'
              type="text"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="paymentType">Payment Type:</label>
            <select
              id="paymentType"
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
              required
              className='bg-gray-900 text-light'
            >
              <option value="" disabled>
                Select a payment type
              </option>
              <option value="Dues">Dues</option>
              <option value="Donation">Donation</option>
              <option value="Registration">Registration</option>
            </select>
          </div>

          <button type="submit" className="submit-button">Submit</button>
        </form>
      ) : (
        <div className="confirmation relative top-16">
          <h2>Confirm Payment Details</h2>
          <p><strong>Name:</strong> {name}</p>
          <p><strong>Amount:</strong>{amount}</p>
          <p><strong>Payment Type:</strong> {paymentType}</p>

          <div className="confirmation-buttons">
            <button className="cancel-button" onClick={handleCancel}>Cancel</button>
            <button className="ok-button" onClick={handleOk}>OK</button>
          </div>
        </div>
      )}
    </PaymentSystem>
    </>
  );
};

export default Payment;

const PaymentSystem = styled.div`
    display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: auto;
  background-color: #f4f4f9;
.h1-title{
    color:#000000 !important;
    max-width:750px;
    padding:1 rem;
    margin:auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.payment-form,
.confirmation {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button.submit-button {
  background-color: #59012b;
  color: white;
}

button.submit-button:hover {
  background-color: #0056b3;
}

.confirmation {
  text-align: center;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

button.cancel-button {
  background-color: #f44336;
  color: white;
  border-radius:15px;
}

button.ok-button {
  background-color: #4caf50;
  color: white;
  border-radius:15px;
}

button.cancel-button:hover {
  background-color: #d32f2f;
}

button.ok-button:hover {
  background-color: #388e3c;
}

/* Responsive styling */
@media (max-width: 480px) {
  .payment-form,
  .confirmation {
    padding: 15px;
  }

  button {
    font-size: 14px;
  }
}
`