import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "./Subcss/gsap.scss";
import video1 from "../video/AfriDayVd1.mp4";

const content = [
  {
    title: "Igbo Cultural Association",
    title1: "Northwest Ireland",
    body: "Preserving Igbo Heritage",
    page1: "Promoting Unity",
    page2: "Offering Community Support",
    motor: "MOTOR: With God, Victory is Sure",
  },
];

const Gsaps = () => {
  const containerRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const animates = gsap.timeline({
      repeat: -1,
      yoyo: true,
      defaults: { duration: 1, delay: 2 },
    });

    animates.fromTo(
      containerRef.current.children,
      { opacity: 0 },
      { opacity: 1 }
    );

    animates.from(".word", {
      y: 100,
      opacity: 0,
      stagger: {
        each: 0.2,
      },
    });
  }, []);

  if (videoRef.current) {
    videoRef.current.playbackRate = 0.3;
    
  }

  return (
    <div className="gsap-cover py-4">
  {/*     <video ref={videoRef} autoPlay loop muted className="video-background">
        <source src={video1} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <div className="overlay"></div>
      <div
        className="gsap-container relative py-4"
        ref={containerRef}
      >
        {content.map(({ title, title1, body, page1, page2, motor }, index) => (
          <div
            key={index}
            className="title-body font-bold text-slate-800  text-center"
            style={{ overflow: "hidden" }}
          >
            <div className="word text-slate-900 d-flex text-center">
              {title.split("").map((word, index) =>
                word === " " ? (
                  <h1 key={index}>&nbsp;</h1>
                ) : (
                  <h1 key={index} className="word text-shadow">
                    {word}
                  </h1>
                )
              )}
            </div>{" "}
            {/* Title */}
            <div className="word text-slate-800 d-flex font-bold text-center">
              {title1.split("").map((word, index) =>
                word === " " ? (
                  <h1 key={index}>&nbsp;</h1>
                ) : (
                  <h1 key={index} className="word text-shadow">
                    {word}
                  </h1>
                )
              )}
            </div>{" "}
            {/* Sub Title */}
            <div className="word text-slate-800 d-flex ">
              {body.split("").map((word, index) =>
                word === " " ? (
                  <h6 key={index}>&nbsp;</h6>
                ) : (
                  <h6 key={index} className="word text-shadow">
                    {word}
                  </h6>
                )
              )}
            </div>{" "}
            {/* Main Body Content */}
            <div className="word text-slate-800 d-flex ">
              {page1.split("").map((word, index) =>
                word === " " ? (
                  <h6 key={index}>&nbsp;</h6>
                ) : (
                  <h6 key={index} className="word text-shadow">
                    {word}
                  </h6>
                )
              )}
            </div>{" "}
            {/* Page1 Content */}
            <div className="word text-slate-800 d-flex ">
              {page2.split("").map((word, index) =>
                word === " " ? (
                  <h4 key={index}>&nbsp;</h4>
                ) : (
                  <h4 key={index} className="word text-shadow">
                    {word}
                  </h4>
                )
              )}
            </div>{" "}
            {/* Page2 Content */}
            <div className="word text-orange-600 d-flex font-bold">
              {motor.split("").map((word, index) =>
                word === " " ? (
                  <h5 key={index}>&nbsp;</h5>
                ) : (
                  <h5 key={index} className="word text-shadow">
                    {word}
                  </h5>
                )
              )}
            </div>{" "}
            {/* Organization Motto */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gsaps;
