import React, { useState } from 'react';
import Yes from './Practice';
import { AuthComponent } from './AuthComponent';

const AuthApps = () => {
  const [authes, setAuthes] = useState(true);
  const [btns, setbtnsToggle] = useState(true);

  const checkAuthorization = (prev) => {
  setAuthes(prev=>!prev)
  setbtnsToggle(prev=>!prev)
  } 

  return (
    <div className='bg-slate-800 min-screen min-h-screen d-flex flex-column justify-center align-items-center gap-5 pt-5'>
      <div className="container d-flex flex-column justify-center align-items-center max-h-50 overflow-hidden">
        <header className="d-flex flex-column justify-center align-items-center py-5">
          <h1 className="text-center text-light p-3 bg-gray">
            Authentication Implementation
          </h1>
          <button className="btn btn-lg btn-primary my-16 max-w-2xl m-auto" onClick={checkAuthorization}>
            {btns ? "Authorized" : "Not-Authorized"}
          </button>
        </header>
        <AuthComponent>
    {/*       {authes ? <Practice /> : <Notallowed />} */}
        </AuthComponent>
      </div>
    </div>
  );
};

export default AuthApps;
