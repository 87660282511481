import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import hand from "../../image/hand.png";
import "../../../CSS/SignUps.scss";
import Axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import TopMenu from "./TopMenu";
import InputMask from "react-input-mask";

const SignUp = () => {
  const navigate = useNavigate();
  const refs = useRef(null);
  const ref_name = useRef(null);
  const ref_nextkin = useRef(null);
  const ref_pw2 = useRef(null);
  const ref_pw = useRef(null);
  const [success, setSuccess] = useState("");
  const [error_name, setErrorName] = useState("");
  const [error_pw, setErrorPw] = useState("");
  const [error_nextkin, setErrorNextkin] = useState("");
  const [error_pw2, setErrorPw2] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [nextkin, setNextKin] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [display, setDisplay] = useState("");

  const handleChanges = (e) => {
    setPhoneNumber(e.target.value);
  };
  const successMessage = () => {
    refs.current.innerHTML = `
        Congrats ${ref_name.current.value}! Welcome onBoard.
        <a href="/Donate" style="color: #007BFF; padding: 3px; text-decoration: none; display: inline-block; margin-top: 10px; border-radius: 10px; text-shadow: 2px 2px 5px rgba(0, 0, 139, 0.5);">
            Proceed to Payment
        </a>
    `;
    refs.current.style.color = "orange";
    refs.current.style.fontWeight = "300";
    refs.current.style.fontFamily = "sans-serif";
    refs.current.style.textTransform = "capitalize";
    refs.current.style.fontSize = "1rem";
};
  const handleRegisterClick = async () => {
    const refName = ref_name.current.value;
    const refPassword = ref_pw.current.value;
    const refPassword2 = ref_pw2.current.value;
    const refNextOfkin = ref_nextkin.current.value;

    const hasSpecialCharacters = /[^\w\s;:&%$£"()#~?<>]/.test(refName);
    const hasUppercase = /[A-Z]/.test(refPassword);
    const hasLetters = /[a-z]/.test(refNextOfkin);

    if (!refName) {
      setErrorName("Please Users Input Fields Should not be Empty");
    } else if (
      ref_name.current.value &&
      ref_name.current.value.includes(hasSpecialCharacters)
    ) {
      setErrorName("Special characters are not allowed please");
    } else if (!refNextOfkin) {
      setErrorNextkin("Please Next of Kin Input Fields Should not be Empty");
    } else if (!refPassword) {
      setErrorPw("Please Password Input Fields Should not be Empty");
    } else if (!refPassword2) {
      setErrorPw("Please Password Input Fields Should not be Empty");
    } else if (password.length < 6) {
      setErrorPw("Password must contain at least 6 characters");
    } else if (refPassword !== refPassword2) {
      setErrorPw("Passwords do not match. Please re-enter your passwords");
    } else if (!hasUppercase) {
      setErrorPw("Password must contain at least one uppercase letter");
    } else if (hasLetters) {
      setErrorNextkin("Next of Kin must be Phone Numbers only, Please");
    } else {
      try {
        const response = await Axios.get(
/*           "http://localhost:3000/php/main/display.php"
 */          "https://igbocommunitynwi.com/php/main/display.php"
        )
          .then((response) => {
            const namesArray = response.data.split("\r\n");
            const cleanedNames = namesArray.filter((name) => name.trim() != "");
            const refValue = ref_name.current.value;

            for (var i = 0; i < cleanedNames.length; i++) {
              console.log("LOOP VALUE ", cleanedNames[i]);
              console.log("REF cureent value ", refValue);
              const cleanedName = cleanedNames[i].trim().toLowerCase();
              const inputValue = refValue.trim().toLowerCase();
              if (cleanedName === inputValue) {
                setErrorName(
                  `Username ${inputValue} Already Exist. Please Choose Another Name!`
                );
                console.log("user exists");
                return;
              }
            }
            setShowOffcanvas(true);
          })
          .catch((error) => {
            console.error("Error fetching names:", error);
          });
      } catch (err) {
        console.log(err.stack);
      }
    }
  };

  const handleSubmit = async () => {
    const refName = ref_name.current.value;
    const refPassword = ref_pw.current.value;
    const refPassword2 = ref_pw2.current.value;
    const refNextOfkin = ref_nextkin.current.value;

    if (!checkboxChecked) {
      setError(
        "Please you must read and acknowledge the terms and conditions first."
      );
    } else {
      try {
        // Proceed with registration
        const registerResponse = await Axios.post(
          /* "http://localhost:3000/php/main/reg.php",*/

          "https://igbocommunitynwi.com/php/main/reg.php",
          {
            name: refName,
            nextkin: refNextOfkin,
            password: refPassword,
            password2: refPassword2,
          }
        );

        setErrorName("");
        setErrorNextkin("");
        setErrorPw2("");
        setErrorPw("");
        setError("");
        successMessage();
        setShowOffcanvas(false);
        document.getElementById("forms").reset();
      } catch (err) {
        console.error("Error occurred:", err);
      }
    }
  };

  const handleCancel = () => {
    setShowOffcanvas(false); // Hide the offcanvas when cancel button is clicked
  };
  return (
    <section>
      <TopMenu className="fixed top-0" />
      <SIGNUP className="sign-ups min-w-screen min-h-screen relative">
        <div className="container form-containers relative">
          <h3 className="relative top-1" ref={refs}>
            sign up 
          </h3>
          <p className="text-light absolute top-24">{success}</p>

          <form action="" className="forms" id="forms">
            <div className="relative">
              <label htmlFor="name">Member</label>

              <input
                style={{ zIndex: "1200" }}
                type="text"
                placeholder="Enter you name or Email"
                className="form-control"
                ref={ref_name}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <p
                className="  text-light text-center  "
                style={{ maxHeight: "5px", padding: "0" }}
              >
                {error_name}
              </p>
            </div>

            <div>
              <label htmlFor="nextkin">NextOfKin</label>
              <InputMask
                mask="+999 999 999 999"
                maskChar="_"
                value={nextkin}
                onChange={(e) => setNextKin(e.target.value)}
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type="text"
                    placeholder="Enter phone number only"
                    className="form-control"
                    ref={ref_nextkin}
                  />
                )}
              </InputMask>
              {error_nextkin && (
                <h6
                  className="text-light text-center"
                  style={{ maxHeight: "5px", padding: "0", margin: "0" }}
                >
                  {error_nextkin}
                </h6>
              )}
            </div>

            <div>
              <label htmlFor="text">Password</label>
              <input
                type="password"
                placeholder="Enter your Password"
                className="form-control"
                ref={ref_pw}
                onChange={(e) => setPassword(e.target.value)}
              />
              {error_pw && (
                <h6
                  className=" text-light text-center"
                  style={{ maxHeight: "5px", padding: "0", margin: "0" }}
                >
                  {error_pw}
                </h6>
              )}
            </div>
            <div>
              <label htmlFor="text">Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm your Password"
                className="form-control"
                ref={ref_pw2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {error_pw2 && (
                <h6
                  className=" text-light text-center"
                  style={{ maxHeight: "5px", padding: "0", margin: "0" }}
                >
                  {error_pw2}
                </h6>
              )}
            </div>
            <div>
              <button
                type="button"
                onClick={handleRegisterClick}
                className="btn btn-lg btn-info form-control"
              >
                Sign up
              </button>
            </div>
            <div className="account-condition mt-2">
              <h6 className="text-light text-muted">
                Already a Member ?{" "}
                <Link to="/Login" className="text-decoration-none me-5 ml-3">
                  Sign In
                </Link>
              </h6>
            </div>
          </form>

          {showOffcanvas && (
            <ModalContainer div className="modal">
              <div className="modal-content container relative top-56">
                <h2 className="py-3">ICANWI Code of Conduct</h2>
                <h4 className=" text-primary">
                  Please read and acknowledge the terms and conditions
                </h4>

                <p>
                  Our Code of Conduct serves as a foundational framework of
                  principles and guidelines meticulously crafted to govern the
                  behavior of all members within the ICANWI community. It
                  reflects our collective commitment to fostering an environment
                  of respect, integrity, and inclusivity, ensuring that every
                  interaction, whether in meetings or events, upholds the values
                  we hold dear.
                </p>
                <h5 className="relative top-1 text-primary">
                  <strong>Key Principles:</strong>
                </h5>
                <p>
                  <span className="font-bold me-1">
                    <strong>Respect:</strong>
                  </span>
                  Treat every member with dignity and respect, regardless of
                  differences in opinion, background, or status. Valuing diverse
                  perspectives enriches our community.
                  <br />
                  <span className="font-bold me-1">Integrity:</span> Conduct
                  yourself with honesty and transparency in all interactions.
                  Uphold the highest ethical standards, both within the group
                  and in external engagements.
                  <br />
                  <span className="font-bold me-1">Collaboration:</span> Foster
                  a spirit of collaboration and cooperation, recognizing that
                  collective effort and teamwork are essential for achieving our
                  shared goals.
                  <br />
                  <span className="font-bold me-1">Accountability:</span> Take
                  responsibility for your actions and their impact on the
                  community. Admit mistakes, learn from them, and strive to do
                  better.
                  <br />
                  <h5 className="relative top-2 bottom-2 text-primary">
                    <strong>Rules and Guidelines:</strong>
                  </h5>
                  <span className="font-bold me-1">Professionalism:</span>{" "}
                  Foster a spirit of collaboration and cooperation, recognizing
                  that collective effort and teamwork are essential for
                  achieving our shared goals.
                  <br />
                  <span className="font-bold me-1">Confidentiality:</span>{" "}
                  Respect the privacy and confidentiality of fellow members.
                  Refrain from disclosing sensitive information shared within
                  the group without explicit consent.
                  <br />
                  <span className="font-bold me-1">
                    Conflict Resolution:
                  </span>{" "}
                  Resolve conflicts and disagreements amicably and respectfully,
                  seeking mediation or assistance from group leaders when
                  necessary..
                  <br />
                  <span className="font-bold me-1">Compliance:</span>: Adhere to
                  all relevant laws, regulations, and policies governing our
                  activities, both within the group and in external engagements
                  <h5 className="relative top-2 bottom-2 text-primary">
                    <strong>Enforcement and Consequences:</strong>
                  </h5>
                  <p>
                    {" "}
                    Violation of the Code of Conduct may result in disciplinary
                    action, determined on a case-by-case basis by group leaders
                    or designated moderators. Consequences may include warnings,
                    temporary suspension of privileges, or permanent expulsion
                    from the group, depending on the severity of the offense.
                  </p>
                </p>
                <label className="text-black">
                  <input
                    type="checkbox"
                    checked={checkboxChecked}
                    onChange={() => setCheckboxChecked(!checkboxChecked)}
                  />
                  {error && <p>{error}</p>}
                  <span className="text-black ml-3 py-3">
                    I have read and agree to the terms and conditions.
                  </span>
                </label>
                <button
                  className="btn btn-info btn-lg text-light "
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="cancel-button btn btn-lg btn-info mt-2 text-light"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <div className="text-red-800 bg-black text-center text-lg"></div>
              </div>
            </ModalContainer>
          )}
        </div>
      </SIGNUP>
    </section>
  );
};

export default SignUp;

const SIGNUP = styled.div`
  padding: 0;
  margin-top: 0;
  box-sizing: border-box;

  .account-condition a {
    background-color: red !important;
    background-image: linear-gradient(45deg, #f3ec78, #af4261) !important;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent !important;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent !important;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
  label {
    font-size: 0.8rem;
    font-weight: 600;
    color: ivory !important;
    z-index: 2500;
  }
  .btn-lg.btn-info.form-control {
    font-size: 1.4rem;
    font-weight: 700;
    z-index: 1000;
    background: green !important;
    border: none;
    text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.6);
    :focus {
      box-shadow: none;
    }
  }
  .nav-brand {
    z-index: 2500 !important;
  }
  .title-headers {
    z-index: 2500;
    h4 {
      color: #00fb1d;
      background: transparent;
      text-shadow: 3px 5px 2px #474747;
      z-index: 2500;
    }
  }
  .form-containers {
    transform: translateY(2rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem !important;
    h3{
      font-family:Arial, Helvetica, sans-serif;
      font-weight: 900;
      font-size:3rem;
      
    }
    ::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: black;
      opacity: 0.5;
      z-index: -2;
    }

    .forms {
      z-index: 3000;
      min-height: 100%;
      height: auto;
      min-width: 60%;

      input {
        margin-bottom: 0.8rem;
        padding: 0.5rem;
        border: none;
        :focus {
          box-shadow: none;
          border: none;
        }
      }
    }
  }
`;


const ModalContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 45000;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  animation: slideDown 0.5s ease; /* Smooth sliding animation */

  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .modal-content {
    background-color: #fcfcfc;
    border-radius: 8px;
    background-color: #fff;
    padding: 40px 4rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.3s ease forwards;
    h5 {
      font-family: Arial, Helvetica, sans-serif;
      color: black;
    }
    labe {
      display: block;
      margin-bottom: 10px;
    }
    input[type="checkbox"] {
      padding: 5%;
      border: 1px solid black !important;
    }
    p {
      font-family: sans-serif;
      font-weight: 300;
      font-style: normal;
    }
    h2 {
      margin-top: 0;
      color: #000 !important;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
`;
