import { useState } from "react";
import {
  Navbar,
  NavLink,
  Container,
  Nav,
  Offcanvas,
  CloseButton,
  Button,
} from "react-bootstrap";
import { BsChevronCompactDown } from "react-icons/bs";
import { BsJustify } from "react-icons/bs";
import "../../../CSS/MenuBar.scss";
import logos from "../../image/NewImagelOGO.png";
import styled from "styled-components";
import Register from "./Register";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayMember from "./DisplayMember";

function TopMenu() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [clicks, setClick] = useState(false);

  let navigate = useNavigate();


  const location = useLocation();
  console.log(location.state);
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasToggle = () =>
    setShowOffcanvas((prevState) => !prevState);

  return (
    <NAVS
      expand="lg"
      variant="light"
      className="px-5 py-2 nav-cover relative fixed-top bg-slate-900"
    >
      <Container>
        <div href="#" className="brand-logo relative">
          <NavLink href="/" className="logo  relative cursor-pointer">
            ICANWI
          </NavLink>

          <span className="logo-text px-3 ">
            <span>Thanks for the support |🤝 </span>
          </span>
      {/*     <LINKERS
            className="navs relative  focus:outline-none shadow-none"
            as={Link}
            to="/Donate"
           >
            <span
            className="btn btn-sm  absolute ml-16 donates-page"
            >
              DONATE
            </span>
          </LINKERS> */}
        </div>

        <div
          className="navbar-toggle relative Nav-btn"
          aria-controls="offcanvasExample"
          onClick={handleOffcanvasToggle}
        >
          <BsJustify
            className="hamburga
           Bjustify hover-cursor"
          />

       {/*    <Button className="btn-menu border-none shadow-none focus:outline-none">
            <span className="btn-menu-text">Menu</span>
            <span className="btn-icon pionter-cursor">
              <BsChevronCompactDown />
            </span>
          </Button> */}
        </div>

        <Canva
          className="off-canva"
          show={showOffcanvas}
          onHide={handleOffcanvasClose}
          placement="start"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <NavLink
                className="navbar-brand text-decoration-none relative nav-side-bar"
                to="/"
              >
                <img
                  src={logos}
                  alt={"newLogo1"}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: "-12px",
                    maxWidth: "200px",
                    maxHeight: "200px",
                    minWidth: "100px",
                  }}
                />
              </NavLink>
            </Offcanvas.Title>
            <CloseButton
              variant="white"
              className=""
              onClick={() => {
                setShowOffcanvas(handleOffcanvasClose);
              }}
            />
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body relative top-8 links-body">
            <Nav className="me-auto d-flex flex-col justify-center Nav">
              <LINKERS
                as={Link}
                to="/"
                target={"_parent"}
                className="navs bg-transparent focus:outline-none shadow-none"
              >
                <span className="span">Home</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none border border-secondary"
                as={Link}
                to="/Gallary"
              >
                <span className="span">Gallery</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Videos"
              >
                <span className="span">Videos</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/PrivatePage"
              >
                <span className="span">Minutes</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Login"
              >
                <span className="span">SignIn</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/CodeOfConduct"
              >
                <span className="span">UpdatePage</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/PrivatePage"
              >
                <span className="span">Constitution</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Acheivement"
              >
                <span className="span">Acheivements</span>
              </LINKERS>

              <LINKERS
                className="navs bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/BlogPages"
              >
                <span className="span">Historical Origins</span>
              </LINKERS>
            </Nav>

            {/*        OFFCANVAS COMPONENT STARTS HERE
             */}
          </Offcanvas.Body>
        </Canva>
        <Nav className="buttons-right px-5">
          <Button
            variant="outline-primary btn1 outline-none shadow-none "
            className="me-3"
            onClick={() => navigate("/Login")}
          >
            <span>Sign-in</span>
          </Button>

          <span>
            <Register />
          </span>
        </Nav>
      </Container>
    </NAVS>
  );
}
export default TopMenu;

const Canva = styled(Offcanvas)`
  z-index: 30000;
  background: #1b001b;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c3b1e1;
  }

  &::-webkit-scrollbar-thumb {
    background: #51414f;
    border-radius: 25px;
  }

  .btn-close {
    color: white !important;
    margin-top: 18px !important;
    margin-right: 8px !important;
  }
  .span {
    text-align: center !important;
  }

`

const LINKERS = styled(Button)`
  color: #fff3b0 !important;
  border-bottom: 1px solid #fff3b0;
  font-size: 1.2rem !important;
  font-family: "Arial" sans-serif;
  font-weight: 700;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  max-height: 60px !important;
  min-height: 60px !important;
  display: grid;
  grid-template-columns: repeat(1, auto);
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 40px;
  background: transparent !important;
  transition: all 0.5s ease-in-out;
  &:hover {
    background: #eb5151 !important;
    color: black !important;
  }
  span {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;

    @media (max-width: 996px) {
      top: 1.3rem !important;
    }
    @media (max-width: 786px) {
      font-size: 1.5rem !important;
      top: 1rem !important;
    }
  }
    /* Donates page styles */
.donates-page {
  color: #fcd09f;
  border: 1px solid #462602;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  min-width: 80px;
  max-width: 80px !important;
  font-weight: 800;
  background: #080d0328;
  transition: all 1s ease-in-out; /* Adjust the transition for smoother animation */
  animation: swing 3s infinite;

  @keyframes swing {
  0%, 100% {
    transform: rotate(0deg); /* No rotation */
    background-color: red; /* Change background color to red */
  }
  25% {
    transform: rotate(15deg); /* Rotate to the right */
    background-color: blue; /* Change background color to blue */
  }
  50% {
    transform: rotate(-10deg); /* Rotate back to the left */
    background-color: green; /* Change background color to green */
  }
  75% {
    transform: rotate(5deg); /* Rotate to the right */
    background-color: #610474; /* Change background color to yellow */
  }
};

@media (max-width: 786px) {
      font-size: 1.2rem !important;
      top: 1rem !important;
      min-width:100px !important;
      width:auto !important;
      left:1rem !important;
    };
      @media (max-width: 550px) {
        left:-1.3rem !important;
      }
      @media (max-width: 450px) {
        font-size: 1rem !important;
        min-width:82px !important;
        left:-3rem !important;

      };

  @media(max-width: 350px) {
  font-size: 0.8rem !important;
  min-width:75px !important;
  top:1.5rem !important;

  }



  
  @media (max-width: 1200px) {
    transform: translateY(-0.4rem) translateX(-1rem) !important;
    border: none !important;
    text-decoration: none !important;
  
    &:after {
    content: "";
    width: 40px;
    height: 2px;
    background: red;
    position: absolute;
    bottom: 0;
    top: 120%;
    left: 30%;
    transition: all 1s ease-in-out; /* Adjust the transition for smoother animation */
    animation: moveRight 3s infinite; /* Apply animation to the ::after pseudo-element */
  }

  /* Pseudo-element for animation */
  &:before {
    content: "";
    width: 40px;
    height: 2px;
    background: red;
    position: absolute;
    bottom: 0;
    top: -14%;
    left: 10%;
    transition: all 1s ease-in-out; /* Adjust the transition for smoother animation */
    animation: moveLeft 3s infinite; /* Apply animation to the ::before pseudo-element */
  }


/* Keyframes for the ::after pseudo-element */
@keyframes moveRight {
  0%, 100% {
    transform: translateX(50%);
    background-color: red;
  }

  50% {
    transform: translateX(0%);
    background-color: blue; /* Change background color in the middle of animation */
  }
}

/* Keyframes for the ::before pseudo-element */
@keyframes moveLeft {
  0%, 100% {
    transform: translateX(-50%);
    background-color: red;
  }

  50% {
    transform: translateX(0%);
    background-color: blue; /* Change background color in the middle of animation */
  }
}

@media (min-width: 997px) {
    transform: translateY(-0.9rem) !important;
  }
}
}
`

const NAVS = styled(Navbar)`
  z-index: 30000;
  background: #0F172A !important;
  @media (max-width: 400px) {
  .hamburga{
   transform: translateX(2rem) !important;
}
  }
  @media (max-width: 992px) {
    .navbar-toggle {
      display: block !important; // Show toggle button on smaller screens
    }

    .buttons-right {
      display: none; // Hide buttons on smaller screens
    }
  };
  @media(max-width:1200px){
  .navbar-toggle{
  .btn-menu{
  transform: translateX(1rem) !important;
  }
  }
  }
`
