import { useState } from "react";
import {
  Navbar,
  NavLink,
  Container,
  Nav,
  Offcanvas,
  CloseButton,
  Button,
} from "react-bootstrap";
import { BsChevronCompactDown } from "react-icons/bs";
import { BsJustify } from "react-icons/bs";
import "../../../CSS/MenuBar.scss";
import logos from "../../image/NewImagelOGO.png";
import styled from "styled-components";
import Register from "./Register";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayMember from "./DisplayMember";

function TopMenu() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [clicks, setClick] = useState(false);

  let navigate = useNavigate();

  const location = useLocation();
  console.log(location.state);
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasToggle = () =>
    setShowOffcanvas((prevState) => !prevState);

  return (
    <NAVS
      expand="lg"
      variant="light"
      className="px-5 py-2 nav-cover relative fixed-top"
    >
      <Container
        className="navbar-off-canva-container"
        style={{ zIndex: "7000" }}
      >
        <div
          href="#"
          className="brand-logo relative"
          style={{ zIndex: "7000" }}
        >
          <NavLink
            href="/"
            className="logo  relative cursor-pointer "
            style={{ zIndex: "7000" }}
          >
            ICANWI
          </NavLink>

          <span className="logo-text px-3 " style={{ zIndex: "7000" }}>
            <span>organization with clear purpose | </span>
          </span>
          <LINKERS
            className=" relative  focus:outline-none shadow-none"
            as={Link}
            to="/"
          >
            <span
              className="btn btn-sm  absolute ml-16 donates-page"
              style={{ zIndex: "7000" }}
            >
              DONATE
            </span>
          </LINKERS>
        </div>

        <div
          style={{ zIndex: "7000" }}
          className="navbar-toggle relative Nav-btn"
          aria-controls="offcanvasExample"
          onClick={handleOffcanvasToggle}
        >
          <BsJustify
            className="hamburga
           Bjustify hover-cursor"
          />

          <Button className="btn-menu border-none shadow-none focus:outline-none">
            <span className="btn-menu-text" style={{ zIndex: "500" }}>
              Menu
            </span>
            <span className="btn-icon pionter-cursor">
              <BsChevronCompactDown style={{ fontSize: "2.5rem" }} />
            </span>
          </Button>
        </div>

        <Canva
          className="of-canva"
          show={showOffcanvas}
          onHide={handleOffcanvasClose}
          placement="start"
        >
          <Offcanvas.Header>
            <Offcanvas.Title>
              <NavLink
                className="navbar-brand text-decoration-none relative nav-side-bar"
                to="/"
              >
                <img
                  src={logos}
                  alt={"newLogo1"}
                  style={{
                    position: "absolute",
                    top: "-3rem",
                    left: "-12px",
                    maxWidth: "200px",
                    maxHeight: "200px",
                    minWidth: "100px",
                    zIndex: "3500",
                  }}
                />
              </NavLink>
            </Offcanvas.Title>
            <CloseButton
              variant="white"
              className=""
              style={{ zIndex: "3500" }}
              onClick={() => {
                setShowOffcanvas(handleOffcanvasClose);
              }}
            />
          </Offcanvas.Header>
          <Offcanvas.Body
            className=" relative top-5"
            style={{ zindex: "2500" }}
          >
            <Nav className="" style={{ zindex: "3000" }}>
              <LINKERS
                as={Link}
                to="/"
                target={"_parent"}
                className=" bg-transparent focus:outline-none shadow-none"
              >
                <span className="span">Home</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none border border-secondary"
                as={Link}
                to="/Gallary"
              >
                <span className="span">Gallery</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Videos"
              >
                <span className="span">Videos</span>
              </LINKERS>

              <LINKERS
                className="bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Login"
              >
                <span className="span">PrivatePages</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Login"
              >
                <span className="span">SignIn</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/UpdatePage"
              >
                <span className="span">Notification</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/Acheivement"
              >
                <span className="span">Acheivements</span>
              </LINKERS>

              <LINKERS
                className=" bg-transparent focus:outline-none shadow-none"
                as={Link}
                to="/BlogPages"
              >
                <span className="span">Historical Origins</span>
              </LINKERS>
            </Nav>

            {/*        OFFCANVAS COMPONENT STARTS HERE
             */}
          </Offcanvas.Body>
        </Canva>

        <Nav className="buttons-right px-5 " style={{ zIndex: "7000" }}>
          <Button
            variant="outline-primary btn1 outline-none shadow-none "
            className="me-3"
            style={{ zIndex: "7000" }}
            onClick={() => navigate("/Login")}
          >
            <span style={{ zIndex: "7000" }}>Sign-in</span>
          </Button>

          <span style={{ zIndex: "7000" }}>
            <Register />
          </span>
        </Nav>
      </Container>
    </NAVS>
  );
}
export default TopMenu;

const Canva = styled(Offcanvas)`
  z-index: 30000;
  background: #1b001b;
  /*   overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #c3b1e1;
  }

  &::-webkit-scrollbar-thumb {
    background: #51414f;
    border-radius: 25px;
  } */

  .btn-close {
    color: white !important;
    margin-top: 18px !important;
    margin-right: 8px !important;
  }
  .span {
    text-align: center !important;
  }
`;

const LINKERS = styled(Button)`
  z-index: 5000;
  color: #fff3b0 !important;
  border-bottom: 1px solid #fff3b0;
  font-size: 1.2rem !important;
  font-family: "Arial" sans-serif;
  font-weight: 700;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  max-height: 60px !important;
  min-height: 60px !important;
  display: grid;
  grid-template-columns: repeat(1, auto);
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 40px;

  transition: all 0.5s ease-in-out;
  text-align: center;
  &:hover {
    background: #eb5151 !important;
    color: black !important;
    cursor: pointer;
  }
  span {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;

    @media (max-width: 996px) {
      top: 1.3rem !important;
    }
    @media (max-width: 786px) {
      font-size: 1.5rem !important;
      top: 1rem !important;
    }
  }
  /* Donates page styles */
  .donates-page {
    z-index: 1000;
    color: #fcd09f;
    border: 1px solid #462602;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    min-width: 80px;
    max-width: 80px !important;
    font-weight: 800;
    background: #080d0328;
    transition: all 1s ease-in-out; /* Adjust the transition for smoother animation */
    animation: swing 3s infinite;

    @keyframes swing {
      0%,
      100% {
        transform: rotate(0deg); /* No rotation */
        background-color: red; /* Change background color to red */
      }
      25% {
        transform: rotate(15deg); /* Rotate to the right */
        background-color: blue; /* Change background color to blue */
      }
      50% {
        transform: rotate(-10deg); /* Rotate back to the left */
        background-color: green; /* Change background color to green */
      }
      75% {
        transform: rotate(5deg); /* Rotate to the right */
        background-color: #610474; /* Change background color to yellow */
      }
    }

    @media (max-width: 786px) {
      font-size: 1.2rem !important;
      top: 1rem !important;
      min-width: 100px !important;
      width: auto !important;
      left: 1rem !important;
    }
    @media (max-width: 550px) {
      left: -1.3rem !important;
    }
    @media (max-width: 450px) {
      font-size: 1rem !important;
      min-width: 82px !important;
      left: -3rem !important;
    }

    @media (max-width: 350px) {
      font-size: 0.8rem !important;
      min-width: 75px !important;
      top: 1.5rem !important;
    }

    @media (max-width: 1200px) {
      transform: translateY(-0.4rem) translateX(-1rem) !important;
      border: none !important;
      text-decoration: none !important;

      &:after {
        content: "";
        width: 40px;
        height: 2px;
        background: red;
        position: absolute;
        bottom: 0;
        top: 120%;
        left: 30%;
        transition: all 1s ease-in-out; /* Adjust the transition for smoother animation */
        animation: moveRight 3s infinite; /* Apply animation to the ::after pseudo-element */
      }

      /* Pseudo-element for animation */
      &:before {
        content: "";
        width: 40px;
        height: 2px;
        background: red;
        position: absolute;
        bottom: 0;
        top: -14%;
        left: 10%;
        transition: all 1s ease-in-out; /* Adjust the transition for smoother animation */
        animation: moveLeft 3s infinite; /* Apply animation to the ::before pseudo-element */
      }

      /* Keyframes for the ::after pseudo-element */
      @keyframes moveRight {
        0%,
        100% {
          transform: translateX(50%);
          background-color: red;
        }

        50% {
          transform: translateX(0%);
          background-color: blue; /* Change background color in the middle of animation */
        }
      }

      /* Keyframes for the ::before pseudo-element */
      @keyframes moveLeft {
        0%,
        100% {
          transform: translateX(-50%);
          background-color: red;
        }

        50% {
          transform: translateX(0%);
          background-color: blue; /* Change background color in the middle of animation */
        }
      }

      @media (min-width: 997px) {
        transform: translateY(-0.9rem) !important;
      }
    }
  }
`;

const NAVS = styled(Navbar)`
 .hamburga{
  z-index:3000;
 }
  @media (max-width: 400px) {
    .hamburga {
      transform: translateX(2rem) !important;
    }
  }
  @media (max-width: 992px) {
    .navbar-toggle {
      display: block !important; // Show toggle button on smaller screens
    }

    .buttons-right {
      display: none; // Hide buttons on smaller screens
    }
  }
  @media (max-width: 1200px) {
    .navbar-toggle {
      .btn-menu {
        transform: translateX(1rem) !important;
      }
    }
  }
`;
